body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.register-form-back-button {
  margin-right: 10px;
}
.cursor-pointer {
  cursor: pointer;
}
.ant-table-container {
  overflow-x: auto;
}

.mainCard {
  min-height: calc(100vh - 165px);
}
@media (max-width: 480px) {
  .ant-card-body {
    padding-left: 15px;
    padding-right: 15px;
  }
}
