.searchbar {
  margin-right: 10px;
  max-width: 500px;
}
.date-dropdown {
  margin-right: 10px;
  width: 150px;
}

.top-filters {
  margin: 20px;
}
