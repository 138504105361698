.appointmentDateSelectBox{
    min-width: 190px;
    width: 100%;
    max-width: 320px;
    margin-bottom: 10px;
}

.btnCancelAppointment{
    display: flex;
    justify-content: center; 
}