.logo_blk {
  display: flex;
  align-items: center;
}

.header {
  padding: 0 30px !important;
  background: teal !important;
}

.rajlogo {
  max-width: 150px;
}

.menu {
  color: #fff;
  line-height: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.menu:hover {
  color: #1890ff;
}

.menu .anticon {
  font-size: 26px;
  margin-right: 8px;
}

.sideMenu .ant-drawer-body {
  background-color: #001529;
  padding-left: 0;
  padding-right: 0;
}

.header-right {
  display: flex;
  align-items: center;
}

.headerProfile {
  color: #fff;
  padding: 5px 10px;
  line-height: 1;
  cursor: pointer;
}

.wrapper {
  min-height: calc(100vh - 64px);
  background: #f1f1f1;
}

.wrapper main {
  padding: 1rem;
}

.menuProfile {
  padding: 0 22px 8px;
  color: #fff;
  border-bottom: 1px solid #ffffff1c;
}

.menuProfile span{
  display: block;
}

/* This will chipkofy in desktop */ 
@media (min-width: 480px) {
  .menuProfile {
    display: none;
  } 
}

/* This will chipkofy in mobile */
@media (max-width: 481px) {
  .headerProfile  {
    display: none;
  }

  .logo_blk .menu {margin-right: auto;}
  .logo_blk .menu span{display: none;}
  .logo_blk .menu span.anticon {display: block;}

  .header {
    padding: 0 12px !important;  
  }
}

.logout-button{
  display: inline-flex; 
  align-items: center;
  position:absolute;
  bottom: 5%;
  right:10%;
}

