
.loader{
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
  }
  .fullLoader{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
  }